<template>
    <div class="dropdown" :class="{ 'is-active': isDropdownOpen }" ref="dropdown">
        <div class="dropdown-trigger">
            <a class="button is-small"  aria-haspopup="true" aria-controls="dropdown-menu" @click="toggleDropdown" :class="!isLarge ? 'is-small' : ''">
                <span>COLUMNS</span>
                <span class="icon is-small">
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                </span>
            </a>
        </div>

        <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
                <div v-for="(column, index) in columns" :key="index" class="dropdown-item">
                    <label class="checkbox">
                        <input type="checkbox" :checked="column.visible"
                            @change="toggleColumnVisibility(index, $event)" />
                        {{ column.name }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ColumnsDropdown",
    props: {
        columns: {
            type: Array,
            required: true,
        },
        isLarge: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            isDropdownOpen: false,
        };
    },
    methods: {
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        toggleColumnVisibility(index, event) {
            // Clone the columns array to avoid directly mutating the prop
            const updatedColumns = [...this.columns];
            updatedColumns[index].visible = event.target.checked;

            // Emit the updated columns back to the parent
            this.$emit("update-columns", updatedColumns);
        },
        handleOutsideClick(event) {
            // Check if the click is outside of the dropdown
            if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
                this.isDropdownOpen = false;
            }
        },
    },
    mounted() {
        // Add a global click event listener
        document.addEventListener("click", this.handleOutsideClick);
    },
    beforeDestroy() {
        // Remove the event listener when the component is destroyed
        document.removeEventListener("click", this.handleOutsideClick);
    },
};
</script>

<style scoped>
.dropdown {
    position: relative;
    display: inline-block;
    width: 200px;
}
</style>
