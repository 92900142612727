<template>
  <div class="order-detail-page">
    <request-pickup-modal :open="selectedOrder ? Boolean(!selectedOrder.external_delivery_id) : false"
      :onClose="() => selectedOrder = null" :order="selectedOrder" />

    <div id="driver-panel-wrapper">
      <nav class="header-custom is-fullwidth level ">
        <div :class="['is-grouped', expandLevel == 1 && 'assign-unassign-container', 'assign-button-container']">
          <a class="button is-small header-btn header-btn-right has-text-weight-medium is-info is-outlined"
            v-on:click="assignNextRoute">
            ASSIGN
          </a>
          <a class="button is-small header-btn header-btn-right  has-text-weight-medium is-info is-outlined"
            v-on:click="unassignSelectedOrders">UNASSIGN
          </a>
          
          <a class="button is-small header-btn is-info is-outlined  has-text-weight-medium header-btn-right"
            v-on:click="selectedOrders.length == 1 && assignOutsourcedDriver()" :disabled="selectedOrders.length != 1">
            <span class="icon is-small" >
              <img src="/img/orders/Uberats.png" alt="Uberats Icon" class="icon-image" style="float: left; margin-right: 5px"/>
            </span>
            <span class="icon is-small">
              <img src="/img/orders/doordash-logo2.png" alt="DoorDash Icon" class="icon-image" style="float: left;"/>
            </span>
          </a>
        </div>
      </nav>

      <div>
        <article class="message is-danger is-small" v-if="driverErrorMsg">
          <div class="message-header">
            <p>Error</p>
            <button class="delete" v-on:click="driverErrorMsg = ''"></button>
          </div>
          <div class="message-body">{{ driverErrorMsg }}</div>
        </article>

        <!-- Driver Table -->
        <div :class="isTabletMode ? 'driver-table-tablet-mode' : 'driver-table'">
          <div class="driver-item" v-for="(d, index) in inStoreDrivers" v-on:dragover.prevent
            v-on:drop="assignOrder(d.driver_id)" v-bind:key="d.driver_id">
            <driver v-bind:driver="d" v-bind:first="index == 0" v-bind:last="index == inStoreDrivers.length - 1"
            v-on:optimizeRoute="retailOptimization ? optimizeTours(d.driver_id) : optimizeRoute(d.driver_id)" v-on:removeRoute="removeRoute(d.driver_id)"
              v-on:selectDriver="selectDriver(d)" v-on:toggleOrderSelection="toggleOrderSelection"></driver>
          </div>

          <div class="driver-item" v-for="(driverOrders, driverName) in instoreOutSourcedDrivers">
            <virtualDriver v-bind:outsourcedOrders="driverOrders" v-bind:virtualDriver="driverName"
              v-on:toggleOrderSelection="toggleOrderSelection"></virtualDriver>
          </div>

          <div class="checker-wrap">
            <!-- <div class="epxand-panel-wrap" v-if="isTabletMode">
              <div>
                <a class="button is-info button-bg is-small expand-panel" v-on:click="toggleExpand()">
                  <i class="fa fa-3x" :class="expandLevel == 3 ? 'fa-chevron-right' : 'fa-chevron-left'"
                    style="color: #808080"></i>
                  <i class="fa fa-3x" :class="expandLevel == 3 ? 'fa-chevron-right' : 'fa-chevron-left'"
                    style="color: #808080"></i>
                </a>
              </div>
            </div> -->
            <div class="checkerboard" :style="{ width: isTabletMode ? '80%' : '100%' }"></div>
          </div>

          <div class="driver-item" v-for="d in onRoadDrivers" v-on:dragover.prevent v-on:drop="assignOrder(d.driver_id)"
            v-bind:key="d.driver_id">
            <driver v-bind:driver="d" v-on:optimizeRoute="retailOptimization ? optimizeTours(d.driver_id) : optimizeRoute(d.driver_id)"
              v-on:removeRoute="removeRoute(d.driver_id)" v-on:selectDriver="selectDriver(d)"
              v-on:toggleOrderSelection="toggleOrderSelection"></driver>
          </div>

          <div class="driver-item" v-for="(driverOrders, driverName) in onTheWayOutSourcedDrivers">
            <virtualDriver v-bind:outsourcedOrders="driverOrders" v-bind:virtualDriver="driverName"
              v-on:toggleOrderSelection="toggleOrderSelection"></virtualDriver>
          </div>
        </div>
      </div>

      <nav v-if="expandLevel !== 1" class="footer-custom is-fullwidth level" :class="{'driver-panel-footer-tablet-mode': isTabletMode }">
        <div class="is-grouped">
          <a class="button is-small header-btn has-text-weight-medium is-info is-outlined"
            v-on:click="toggleNewDriverModal()">
            <span class="fa fa-plus"></span>
          </a>
          <a class="button is-small header-btn has-text-weight-medium is-info is-outlined"
            v-on:click="removeOnDutyDriver()">
            <span class="fa fa-trash"></span>
          </a>
          <a class="button is-small header-btn has-text-weight-medium is-info is-outlined" v-if="!isTabletMode"
            v-on:click="dispatchDriver()">
            <span class="fa fa-arrow-circle-right"></span>
          </a>
          <a class="button is-small header-btn has-text-weight-medium is-info is-outlined" v-on:click="returnDriver()">
            <span class="fa fa-exchange"></span>
          </a>
          <a class="button is-small header-btn has-text-weight-medium is-info is-outlined"
            v-on:click.stop="moveDriverDown" :disabled="!selectedDriver || !selectedDriver.driver_id">
            <span class="fa fa-angle-down fa-2x"></span>
          </a>
          <a class="button is-small header-btn has-text-weight-medium is-info is-outlined"
            v-on:click.stop="moveDriverUp" :disabled="!selectedDriver || !selectedDriver.driver_id">
            <span class="fa fa-angle-up fa-2x"></span>
          </a>
        </div>
      </nav>

      <modal v-bind:isActive="editDriverModalStatus" v-on:close="toggleEditDriverModal"></modal>

      <modal v-bind:isActive="newDriverModalStatus" v-on:close="toggleNewDriverModal">
        <div slot="header">Add Driver to Shift</div>
        <div slot="body">
          <div class="field">
            <p class="control">
              <span class="select">
                <select class="is-uppercase" v-model="selectedAddDriver">
                  <option disabled value>Please select a driver</option>
                  <option class="is-uppercase" v-for="driver in offDutyDrivers" v-bind:key="driver.driver_id"
                    :value="driver">
                    {{
                    driver.driver_name +
                    (driver.DriverLastName ? ` ${driver.DriverLastName}` : "")
                    }}
                  </option>
                </select>
              </span>
            </p>
          </div>
        </div>
        <div slot="footer">
          <a class="button" v-on:click="addOnDutyDriver()">Add</a>
        </div>
      </modal>

      <prompt v-on:removeRoute="removeRoute(driver_id)" v-on:removeDriver="removeOnDutyDriver()"
        v-on:returnDriver="returnDriver()"></prompt>

      <open_store_prompt></open_store_prompt>
    </div>
  </div>

</template>

<script>
var modal = require("../modal.vue").default;
var driver = require("./driver.vue").default;
var prompt = require("../prompt.vue").default;
var open_store_prompt = require("../accounts/close_day_schedule_prompt.vue").default;
const custom = require("../../js/custom.js");
var virtualDriver = require("./virtual_driver.vue").default;
var requestPickupModal = require("../add_edit_order/request_pickup_modal.vue").default;

module.exports = {
  components: {
    modal,
    driver,
    prompt,
    virtualDriver,
    requestPickupModal,
    open_store_prompt
  },
  data: function () {
    return {
      base_url: this.$store.state.base_url,
      api_url: this.$store.state.api_url,
      hub_url: this.$store.state.hub_url,
      store_id: this.$store.state.user.store_id,
      drivers: this.$store.state.drivers.all,
      routes: this.$store.state.routes.all,
      orders: this.$store.state.orders.all,
      driverError: "", // Determine if there is an error message
      driverErrorMsg: "", // Show which error message to display
      turnOnAutomation: {},

      // probably need to migrate to VUEX
      driverErrorMessages: {
        NDS: "No driver selected.",
      }, // List of error
      newDriver: {
        name: "",
        return: "",
        run: "",
        total: "",
      },
      editDriver: {
        name: "",
        return: "",
        run: "",
        total: "",
      },
      newDriverModalStatus: false,
      editDriverModalStatus: false,
      selectedAddDriver: "", // Add on duty driver selected driver
      assignedOrder: this.$store.state.orders.draggedOrder,
      driver_id: "",
      isTabletMode: false,
      currentDate: new Date(Date.now()),
      selectedOrder: null,
    };
  },
  created: function () {
    this.getDrivers();
  },
  computed: {
    retailOptimization: function () {
        return this.$store.getters["orders/getStore"]['retailOptimization']
    },
    storeInfo: function () {
      return this.$store.getters["orders/getStore"];
    },
    expandLevel: function () {
      return this.$store.getters["app/getExpandLevel"];
    },
    selectedOrders: function () {
      return this.$store.getters["orders/selectedOrders"];
    },
    onDutyDrivers: function () {
      return this.$store.getters["drivers/onDutyDrivers"];
    },
    inStoreDrivers: function () {
      return this.$store.getters["drivers/inStoreDrivers"];
    },
    onRoadDrivers: function () {
      return this.$store.getters["drivers/onRoadDrivers"];
    },
    offDutyDrivers: function () {
      const offDuty = this.$store.getters["drivers/offDutyDrivers"];
      offDuty.sort((a, b) => {
        return a.driver_name.toUpperCase() > b.driver_name.toUpperCase()
          ? 1
          : -1;
      });
      return offDuty;
    },
    selectedDriver: function () {
      return this.$store.getters["drivers/selectedDriver"];
    },
    inStoreOrders: function () {
      return this.$store.getters["orders/inStoreOrders"];
    },
    currentPanel: function () {
      return this.$store.getters["app/getCurrentPanel"];
    },
    instoreOutSourcedDrivers() {
      const instoreOutSourcedOrders = (this.$store.getters["orders/outsourcedOrders"] || []).filter(
        (o) => ['pending', 'pickup', undefined, null].includes(o.external_delivery_status) && !o.delivery_time && !o.is_return
      );
      const driverMap = instoreOutSourcedOrders.reduce((acc, order) => {
          const key = order.outSourcedDriverName !== null && order.outSourcedDriverName != '' ? order.outSourcedDriverName : order.external_delivery_source;
          acc[key] = acc[key] ? [...acc[key], order] : [order];
          return acc;
      }, {});

      return driverMap;
    },
    onTheWayOutSourcedDrivers() {
      const onTheWayOutSourcedOrders = (this.$store.getters["orders/outsourcedOrders"] || []).filter(
        (o) => !['pending', 'pickup', undefined, null, 'canceled', 'canceling'].includes(o.external_delivery_status) && !o.delivery_time && !o.is_return
      );
      const driverMap = onTheWayOutSourcedOrders.reduce((acc, order) => {
          const key = order.outSourcedDriverName !== null && order.outSourcedDriverName != '' ? order.outSourcedDriverName : order.external_delivery_source;
          acc[key] = acc[key] ? [...acc[key], order] : [order];
          return acc;
      }, {});

      return driverMap;
    },
  },
  mounted() {
    this.checkTabletMode();
    window.addEventListener("resize", this.checkTabletMode);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkTabletMode);
  },
  methods: {
    checkTabletMode() {
      this.isTabletMode = custom.checkIfTablet();
    },
    toggleExpand: function () {
      this.$store.dispatch("app/toggleExpand", this.isTabletMode);
    },
    toggleOrderSelection: function (order) {
      this.$store.dispatch("orders/toggleOrderSelection", order);
    },
    getDrivers: function () {
      this.$store.dispatch("drivers/getDrivers");
    },
    assignSelectedOrders() {
      this.$store.dispatch("orders/assignOrders", {
        driver_id: this.selectedDriver.driver_id,
        orders: this.selectedOrders,
      });
    },
    unassignSelectedOrders() {
      this.$store.dispatch("orders/unassignOrders", this.selectedOrders);
    },
    getRoutes: function (callback) {
      var payload = {
        store_id: this.store_id,
        method: "GET",
      };

      this.$http.post(this.base_url + "routes/", payload).then(
        (res) => {
          if (res.body.result != 0) {
            alert(res.body.error);
          } else {
            // Load routes into Vuex
            this.$store
              .dispatch("routes/updateRoutes", res.body.routes)
              .then(function () {
                if (typeof callback === "function") {
                  callback();
                }
              });
          }
        },
        (res) => {
          console.log("Failed to get routes...", res);
        }
      );
    },
    selectDriver: function (driver) {
      if (this.driverError == "NDS") {
        this.driverError = "";
      }

      if (driver.driver_id == this.selectedDriver?.driver_id) {
        this.$store.dispatch("drivers/deselectDriver");
        if (this.currentPanel == "dayPanel") {
          this.s();
        }
      } else {
        this.$store.dispatch("drivers/selectDriver", driver);
      }
    },
    assignOutsourcedDriver: function () {
        if(this.selectedOrders && this.selectedOrders.length == 0) {
          this.$store.dispatch("app/togglePromptModal", 'NO_ORDER_SELECTED_FOR_VIRTUAL_DRIVERS');
          return;
        } 
        if(this.selectedOrders && this.selectedOrders.length > 1){
          this.$store.dispatch("app/togglePromptModal", 'MANY_ORDERS_SELECTED_FOR_VIRTUAL_DRIVERS');
          return;
        }
        this.selectedOrder = this.inStoreOrders.find(o => o.order_id == this.selectedOrders[0])
    },
    assignNextRoute: function () {
      if (this.selectedDriver == "" || this.selectedDriver == null) {
        this.driverError = "NDS";
        this.driverErrorMsg = this.driverErrorMessages.NDS;
      } else {
        if (this.selectedOrders && this.selectedOrders.length) {
          const driverRoute = this.selectedDriver.route.split(",").filter(route => route.trim() !== "");
          const driverRouteCount = driverRoute.length;
          const ordersCount = driverRouteCount + this.selectedOrders.length;
          if (this.$store.getters["orders/getStore"].packageId != 1 &&  ordersCount > 7) {
            this.$store.dispatch("app/togglePromptModal", 'SAFETY_MEASURE');
            return;
          }
          this.$store.dispatch("orders/assignOrders", {
            driver: this.selectedDriver,
            orders: this.selectedOrders,
          });
        } else {
          if(this.$store.getters["orders/getStore"].packageId == 1){
            this.$store.dispatch("app/togglePromptModal", 'NO_ORDERS_SELECTED');
            return;
          }

          // assign Driver next route
          var payload = {
            store_id: this.store_id,
            method: "POST",
            driver_id: this.selectedDriver.driver_id,
          };

          this.$http.post(this.base_url + "route/next/assign/", payload).then(
            (res) => {
              if (res.body.result != 0) {
                if(res.body.result == 3){
                  this.$store.dispatch("app/togglePromptModal", 'NO_DRIVER_SELECTED');
                }
                else if(res.body.result == 11){
                  this.$store.dispatch("app/togglePromptModal", 'NO_ORDERS_SELECTED');
                }
                // else{
                //   alert(res.body.error)
                // }
              } 
            },
            (res) => {
              console.log("Failed to assign next route...", res);
            }
          );
        }
      }
    },
    dispatchDriver: function () {
      // MikeE Wait for routes to be updated prior to continuing checking.  Figure out solution or don't check.
      this.getRoutes(this.dispatchDriverRoutesUpdated);
    },
    dispatchDriverRoutesUpdated: function () {
      var routeReady = true;

      // check if driver is selected
      if (this.selectedDriver == "") {
        this.driverError = "NDS";
        this.driverErrorMsg = this.driverErrorMessages.NDS;
      } else {
        // Check if all orders listed on route are ready
        // Load orders that relate to the selected driver into
        var s_driver_id = this.selectedDriver.driver_id;
        var d_route = this.routes.filter(function (order) {
          return order.driver_id == s_driver_id;
        });

        for (var order in d_route) {
          if (
            !d_route[order].is_ready_to_deliver &&
            d_route[order].customer_name
          ) {
            routeReady = false;
          }
        }
        // Check if all orders in dispatchRoute are ready
        if (routeReady) {
          // return Driver
          var payload = {
            store_id: this.store_id,
            method: "POST",
            driver_id: this.selectedDriver.driver_id,
          };

          this.$http.post(this.base_url + "driver/dispatch/", payload).then(
            (res) => {
              const { result, error } = res.body;

              if (result !== 0) {
                if (result === 7) {
                  this.$store.dispatch("app/togglePromptModal", 'NOT_READY', { root: true });
                } else if (result === 3) {
                  this.$store.dispatch("app/togglePromptModal", 'NO_DRIVER_SELECTED', { root: true });
                } else {
                  alert(error);
                }
              } else {
                const assignedOrdersByDriverId = this.assignedOrdersByDriverId(this.selectedDriver.driver_id);
                (assignedOrdersByDriverId || []).forEach(order => {
                  this.$store.dispatch("orders/removeOrderFromSelection", order);
                })
                this.$store.dispatch("drivers/deselectDriver");
              }
            },
            (res) => {
              console.log("Failed to dispatch driver...", res);
            }
          );
        } else {
          this.driverErrorMsg =
            "Not all orders in route are ready to be delivered";
        }
      }
    },

    // Get selected driver current day order
    s: function () {
      var storeId = this.$store.getters["getStoreID"];
      par = {
        driverId: this.selectedDriver.driver_id,
        storeId: storeId,
        base_url: "",
      };
      this.$store.dispatch("orders/getCurrentDayOrders", par);
    },
    assignedOrdersByDriverId: function (driver_id) {
      return this.$store.getters["orders/assignedOrdersByDriverId"](
        driver_id
      );
    },
    returnDriver: function () {
      // check if driver is selected
      if (!this.selectedDriver) {
        this.driverError = "NDS";
        this.driverErrorMsg = this.driverErrorMessages.NDS;
      } else {
        const assignedOrdersByDriverId = this.assignedOrdersByDriverId(this.selectedDriver.driver_id);

        const allAssignedOrderAreDelivered = assignedOrdersByDriverId.every(item => item.delivery_time !== null);
        if(!allAssignedOrderAreDelivered){
          if(!this.$store.state.app.showPromptModal){
            this.$store.dispatch("app/togglePromptModal", 'NOT_ALL_ORDERS_ARE_DELIVERED');
            return
          } else {
            this.$store.dispatch("app/togglePromptModal", 'NOT_ALL_ORDERS_ARE_DELIVERED');
          }
        }

        // retrun Driver
        var payload = {
          store_id: this.store_id,
          method: "POST",
          driver_id: this.selectedDriver.driver_id,
        };

        this.$http.post(this.base_url + "driver/return/", payload).then(
          (res) => {
            if (res.body.result != 0) {
              alert(res.body.error);
            }

            this.$store.dispatch("drivers/deselectDriver");
          },
          (res) => {
            console.log("Failed to return driver...", res);
          }
        );
      }
    },
    toggleEditDriverModal: function () {
      // Check if driver is selected
      if (this.editDriverModalStatus == false && this.selectedDriver == "") {
        this.driverError = "NDS";
        this.driverErrorMsg = this.driverErrorMessages.NDS;
      }

      if (this.driverError == "") {
        this.editDriverModalStatus = !this.editDriverModalStatus;
      }
    },
    toggleNewDriverModal: function () {
      this.newDriverModalStatus = !this.newDriverModalStatus;
    },
    addOnDutyDriver: function () {
      const payload = {
        storeId: this.store_id,
        driverId: this.selectedAddDriver.driver_id,
        phoneNumber: this.selectedAddDriver.phone_number,
        onDuty: true
      };

      this.$http
        .post(this.api_url + "Api/V1/Drivers/AddUpdateDriver/", payload)
        .then(
          (res) => {
            if (res.status != 200) {
              alert(res.body);
            }
            this.newDriverModalStatus = !this.newDriverModalStatus;
          },
          (res) => {
            console.log("Failed add driver: ", this.selectedAddDriver);
            console.log("Error", res.body);
          }
        );
    },
    removeOnDutyDriver: function () {
      if (this.selectedDriver?.route_status != "No route") {
        this.driverErrorMsg =
          "Drivers must be returned before being clocked out, complete all assigned orders and return driver and try again.";
      } else {
        
        if(!this.$store.state.app.showPromptModal){
          this.$store.dispatch("app/togglePromptModal", 'REMOVE_DRIVER')
          return
        } else {
          this.$store.dispatch("app/togglePromptModal", 'REMOVE_DRIVER')
        }

        const payload = {
          storeId: this.store_id,
          driverId: this.selectedDriver.driver_id,
          phoneNumber: this.selectedDriver.phone_number,
          onDuty: false
        };

        this.$http
          .post(this.api_url + "Api/V1/Drivers/AddUpdateDriver/", payload)
          .then(
            (res) => {
              if (res.status != 200) {
                alert(res.body);
              }
            },
            (res) => {
              console.log("Failed remove driver: ", this.selectedDriver);
              console.log("Error", res.body);
            }
          );
      }
    },
    moveDriverUp: function () {
      const driver_id = this.selectedDriver.driver_id;

      var priorDriver = null;
      this.onDutyDrivers.forEach((d) => {
        if (
          priorDriver &&
          d.driver_id == driver_id &&
          d.time_til_back_to_shop == 0
        ) {
          console.log("Prior Driver: " + priorDriver.driver_name);
          console.log("Moving Driver: " + d.driver_name);
          // Swap Last Arrived.
          var payload = {
            store_id: this.store_id,
            method: "POST",
            driver_id_to_move: d.driver_id,
            driver_id_before_driver_to_move: priorDriver.driver_id,
          };
          this.$http.post(this.base_url + "drivers/reorder2/", payload).then(
            (res) => {
              if (res.body.result != 0) {
                alert(res.body.error);
              }
            },
            (res) => {
              console.log("Failed to move driver...", res.body);
            }
          );
        }
        priorDriver = d;
      });
    },
    moveDriverDown: function () {
      const driver_id = this.selectedDriver.driver_id;

      var currentDriver = null;
      var loop = true;
      this.onDutyDrivers.forEach((d) => {
        if (
          (loop && d.driver_id == driver_id && d.time_til_back_to_shop == 0) ||
          (currentDriver && loop)
        ) {
          if (currentDriver && d.time_til_back_to_shop == 0) {
            var nextDriver = d;
            // Swap Last Arrived.
            var payload = {
              store_id: this.store_id,
              method: "POST",
              driver_id_to_move: nextDriver.driver_id,
              driver_id_before_driver_to_move: currentDriver.driver_id,
            };
            loop = false; // Found the reorder couple.
            this.$http.post(this.base_url + "drivers/reorder2/", payload).then(
              (res) => {
                if (res.body.result != 0) {
                  alert(res.body.error);
                }
              },
              (res) => {
                console.log("Failed to move driver...", res.body);
              }
            );
          }
          currentDriver = d;
        }
      });
    },
    removeRoute: function (driver_id) {
      this.driver_id = driver_id

      // Generate HTTP request payload
      var payload = {
        store_id: this.store_id,
        method: "GET",
        driver_id: driver_id,
      };

      if(!this.$store.state.app.showPromptModal){
        this.$store.dispatch("app/togglePromptModal", 'REMOVE_ROUTE');
        return
      } else{
        this.$store.dispatch("app/togglePromptModal", 'REMOVE_ROUTE');
      }

      this.$http.post(this.base_url + "driver/route/cancel/", payload).then(
        (res) => {
          if (res.body.result == 0) {
            this.$store.dispatch("orders/clearSelectedOrders");
            if (driver_id == this.selectedDriver?.driver_id) {
              this.$store.dispatch("drivers/deselectDriver");
            }
          } else {
            alert(res.body.error);
          }
        },
        (res) => {
          console.log("Failed to remove route...", res.body);
        }
      );
    },
    optimizeTours: function (driver_id) {
      this.$store.dispatch("drivers/setDriverToOptimizeTours", driver_id);
      this.$store.dispatch("app/togglePromptModal", 'OPTIMIZE_TOURS');
    },
    optimizeRoute: function (driver_id) {
      console.log("Optimizing Route for: " + driver_id);

      // Generate HTTP request payload
      var payload = {
        store_id: this.store_id,
        method: "GET",
        driver_id: driver_id,
      };

      this.$http.post(this.base_url + "driver/route/optimize/", payload).then(
        (res) => {
          if (res.body.result != 0) {
            alert(res.body.error);
          }
        },
        (res) => {
          console.log("Failed to optimize route...", res.body);
        }
      );
    },
    assignOrder: function (driver_id) {
      var payload = {
        store_id: this.store_id,
        method: "POST",
        order_id: this.assignedOrder.order_id,
        driver_id: driver_id,
      };

      this.$http.post(this.base_url + "driver/route/order/add/", payload).then(
        (res) => {
          if (res.body.result != 0) {
            alert(res.body.error);
          }
        },
        (res) => {
          console.log("Failed to assign order...", res.body);
        }
      );
    },
  },
};
</script>

<style scoped>

.icon-image {
  width: 25px;
}

.mover-btn {
  margin-left: 2px;
  margin-right: 2px;
}

li {
  color: #7a7a7a;
  font-size: 14px;
}

/* Driver Management Buttons */
.route-btn-left {
  padding-right: 6px !important;
}

.route-btn-right {
  padding-left: 6px !important;
}

#driver-panel-wrapper {
  /* height: calc(100vh - 18px); */
  background-color: white;
  display: block;
  margin: 0px;
}

.driver-item {
  padding-top: 0.5em;
  padding-bottom: 0.8em;
}

.driver-item:hover {
  cursor: pointer;
}

.driver-name {
  color: #363636;
  font-weight: 700;
  font-size: 16px;
}

.stat-title {
  font-weight: 700;
}

.stat {
  float: right;
}

.dm-wrapper {
  border-top: 1px solid #dbdbdb;
  position: absolute;
  bottom: 0px;
  left: 13px;
  width: 250px;
}

.dm-wrapper > li {
  /* display: inline; */
  text-align: center;
}

.dm-wrapper > li > a {
  align-items: center;
  color: #363636;
  font-size: 20px;
}

.header-custom {
  border-bottom: 1px solid #dbdbdb;
  padding: 5px;
  background-color: white;
  margin-bottom: 0px !important;
  justify-content: center;
}

.footer-custom {
  border-top: 1px solid #dbdbdb;
  padding: 5px;
  background-color: white;
  margin-bottom: 0px !important;
  justify-content: center;
}

.footer-custom .header-btn {
  width: 32px;
}

.checker-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkerboard {
  margin-top: 20px;
  margin-bottom: 15px;
  height: 40px;
  background-image: linear-gradient(45deg, #808080 25%, transparent 25%),
    linear-gradient(-45deg, #808080 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #808080 75%),
    linear-gradient(-45deg, transparent 75%, #808080 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transform: translateX(-100%);
  margin-left: 0.5rem;
}

.assign-unassign-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.chevron-right {
  margin-left: 0.2rem;
}

.button-bg {
  background-color: #5e95ed !important;
}

.epxand-panel-wrap {
  margin-top: 5px;
}
.expand-panel {
  background-color: #fff !important;
  height: 40px;
}

.expand-panel > i:nth-child(2) {
  margin-left: -5px;
}

.driver-table {
  height: calc(100vh - 9em);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.driver-table-tablet-mode{
  height: calc(100vh - 7em);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.time {
  text-align: center;
  padding: 0.5em;
}

.driver-panel-footer-tablet-mode{
  padding-top: 1em;
}

.assign-button-container{
  display: flex;
  flex-wrap: nowrap;
  align-items: center; 
}
.assign-button-container .button {
  flex-shrink: 0; 
}
</style>
