const moment = require('moment-timezone')

module.exports = {
  stringTimeDiff: function (ETA, ENTRY) {
    if (typeof ETA == "string" && typeof ENTRY == "string") {
      var parsedETA = new Date(parseInt(ETA.substring(6, ETA.length - 2)));
      var parsedENTRY = new Date(
        parseInt(ENTRY.substring(6, ENTRY.length - 2))
      );
      var TTD = parsedETA - parsedENTRY;
      return Math.round(TTD / 60000);
    }
  },

  convertDate: function (value) {
    if (value)
      return isNaN(value) ? new Date(
        parseInt(value.substring(6, value.length - 2))
      ) : new Date(
        parseInt(value)
      )
    else return null;
  },

  timeDiff: function (value1, value2) {
    if (value1 && value2) {
      return Math.round((value1.getTime() - value2.getTime()) / 60000);
    } else {
      return null;
    }
  },

  elapsedTime: function (value) {
    if (value) {
      const now = new Date();
      const entryTime = isNaN(value) ? new Date(
        parseInt(value.substring(6, value.length - 2))
      ) : new Date(
        parseInt(value)
      )

      const ELP = now - entryTime;
      return Math.round(ELP / 60000);
    }
  },
  currentTime: function () {
    const now = new Date();
    return Math.round(now / 60000);
  },
  convertToTime: function(value){
    if (value) {
      const entryTime = isNaN(value) ? new Date(
        parseInt(value.substring(6, value.length - 2))
      ) : new Date(
        parseInt(value)
      )
      return Math.round(entryTime / 60000);  
    }
  },
  formatTime: function (dateTime) {
      // Extract the timestamp from the /Date(...)/ format
      const timestampMatch = dateTime.match(/\/Date\((\d+)\)\//);
      if (!timestampMatch) return '--'; // Return "--" if the format is incorrect

      const timestamp = parseInt(timestampMatch[1], 10);
      const date = new Date(timestamp);

      let hours = date.getHours();
      let minutes = date.getMinutes();

      // Ensure two digits for hours and minutes
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;

      return `${hours}:${minutes}`;
  },
  formatDateTime: function (value, withTime) {
    const dt = new Date(value);
    const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);

    const date = `${padL(dt.getMonth() + 1)}/${padL(dt.getDate())}/${dt.getFullYear()}`;
    const time = dt.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: true})

    return date + (withTime ? ` ${time}` : "");
  },
  getTime: function(value){
    const dt = new Date(value);
    const time = dt.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: true})
    return time;
  },

  getZeroPad: function (n) {
    return (parseInt(n, 10) >= 10 ? '' : '0') + n
  },


  convertEasternTime(value) {
    if (value) {
      return new moment(value, 'YYYY-M-D HH:mm:ss')
        .tz("America/New_York");
    }
    return null;
  },

  formatDate(date) {
    // Get the local date components
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    // Return the formatted date string
    return `${year}-${month}-${day}`;
  },

  addMinutesToCurrentTime(minutesToAdd) {
    const currentDate = new Date();
    const minutes = parseInt(minutesToAdd, 10);

    // Add the minutes to the current time
    currentDate.setMinutes(currentDate.getMinutes() + minutes);

    // Format the result to HH:mm
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutesFormatted = currentDate.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutesFormatted}`;
  }
}